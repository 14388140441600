import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
// import { Header, ButtonCss } from './PaymentPopup.styles'
// import WalletImage_ from './assets/wallet-image.png'
// import API from '../../../services/api'
import { ButtonCss, Header } from '../PaymentModal.styled'
import { WalletInfoImage } from '@Components/Layout/Header/Assets/images'
import { useCheckoutSingleCourseMutation } from '@Features/Classroom/ClassroomSlice/ClassroomSlice'
import { useGetUserProfileQuery } from '@Features/Dashboard/DashboardSlice/Dashboard.slice'
import { useTranslation } from 'react-i18next'
// import { useTranslation } from 'react-i18next'
// import { refreshWalletValue } from './Utils'
import FacebookPixel from 'react-facebook-pixel'
import { PromocodeButton } from './PromocodeButton'
import Spacer from '@eduact/ed-components/Spacer'
import { Typography } from '@eduact/ed-system'
import EdTypography from '@Components/Utilities/EdTypography/EdTypography'

type Props = {
	setActiveSection: any
	courseId: any
	setShowPaymentPopup: any
	onSuccess: any
	onReject: any
	price: any
	classroomName: any
}
const WalletPayment: React.FC<Props> = ({ setActiveSection, courseId, setShowPaymentPopup, onSuccess, onReject, price, classroomName }) => {
	const userProfile = useGetUserProfileQuery()
	const [checkout, checkoutData] = useCheckoutSingleCourseMutation()

	const [discount, setDiscount] = useState<any>()
	const onApply = (value: any) => {
		setDiscount(value)
	}
	const submitHandler = () => {
		checkout({ course_id: courseId, payment_method: 'wallet', promocode: discount?.code })
	}
	const { t } = useTranslation(['payment'], { keyPrefix: 'wallet_payment' })
	const { t: tGeneric } = useTranslation(['generic'])
	useEffect(() => {
		if (checkoutData.isSuccess) {
			FacebookPixel.track('Purchases', {
				content_ids: [courseId],
				content_name: classroomName,
				delivery_category: 'in_store',
				currency: 'EGP',
				value: price,
			})
			onSuccess && onSuccess()
		}
	}, [checkoutData.isSuccess, onSuccess])

	return (
		<Container>
			<Header>
				{t('title')} {classroomName} {t('for')} <Typography textDecoration={discount && 'line-through'}>{price}</Typography>{' '}
				{discount && <EdTypography color="primary">{discount.value}</EdTypography>} {tGeneric('egp')}
			</Header>
			<Spacer mb="2rem" />
			<PromocodeButton price={price} onApply={onApply} />
			<Spacer mb="2rem" />
			<ContentWrapper>
				<Image src={WalletInfoImage} alt="wallet image" />
				<ConfirmationWrapper>
					<Text mobileMargin="26px">
						{t('balance')} {userProfile.data?.data.student.wallet.amount} {tGeneric('egp')} {t('balance2')}
					</Text>
					<YesButton onClick={submitHandler}>{t('yes')}</YesButton>
					<CancelButton onClick={() => setActiveSection('payment-choice')}>{t('cancel')}</CancelButton>
				</ConfirmationWrapper>
			</ContentWrapper>
		</Container>
	)
}

const Container = styled.div`
	button {
		cursor: pointer;
	}
`
const ContentWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	padding-top: 26px;

	@media (max-width: 425px) {
		align-items: center;
		flex-direction: column;
	}
`

const Image = styled.img`
	width: 240px;
`

const ConfirmationWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`

const Text = styled.p<{ mobileMargin: any }>`
	font-family: Montserrat, serif;
	font-size: 12px;
	color: #9b9b9b;
	margin-bottom: 26px;

	@media (max-width: 425px) {
		margin-top: ${props => props?.mobileMargin};
	}
`

const YesButton = styled.button`
	${ButtonCss};
	background-color: #5ac0fc;
	margin-bottom: 10px;
`

const CancelButton = styled.button`
	${ButtonCss};
	background-color: #ff8532;
`

export default WalletPayment
