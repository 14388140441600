import { rtkPrepareHeadersCallback } from '@Api/Api'
import { BaseApi } from '@Features/Base/BaseApi/BaseApi'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react'
import { env } from 'process'

const InstructorApi = BaseApi.enhanceEndpoints({
	addTagTypes: ['Instructors', 'Instructor', 'CLASSROOM'],
}).injectEndpoints({
	endpoints: builder => ({
		getAllInstructors: builder.query<ApiResponse<BriefInstructor[]>, void>({
			query: () => ({ url: '/instructor/all' }),
			providesTags: ['Instructors'],
		}),
		getInstructorByLabel: builder.query<ApiResponse<Instructor>, string>({
			query: label => ({ url: `/instructor?label=${label}` }),
			providesTags: (result, error, args) => [{ type: 'Instructor', id: result?.data.user_id }],
		}),
		addInstructorCode: builder.mutation<ApiResponse<Instructor>, AddCode>({
			query: payload => ({
				url: '/instructor/apply',
				method: 'POST',
				body: {
					code: payload.code,
					instructorId: payload.instructorId,
				},
			}),
			invalidatesTags: (result, error, arg) => 
				[{ type: 'Instructor', id: arg.instructorId },{type : 'CLASSROOM'}]
			,
		}),
	}),
})

export const { useGetAllInstructorsQuery, useGetInstructorByLabelQuery, useAddInstructorCodeMutation } = InstructorApi
export default InstructorApi
