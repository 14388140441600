import routes from '@Constants/Routes'
import {
	BottomSheet,
	IEssayQuestion,
	isEssayQuestion,
	Question,
	RaisedButton,
	TestQuestion,
	UploadProgressArgs,
	useSnackbar,
} from '@eduact/ed-components'
import Spacer from '@eduact/ed-components/Spacer'
import { FlexLayout } from '@eduact/ed-system'
import { uploadFileWithProgress, useLazyUploadQuery } from '@Features/Base/BaseApi/BaseApi'
import { useLazySubmitQuestionQuery } from '@Features/Test/Api/TestApi'
import useSubmitTest from '@Features/Test/Hooks/useSubmitTest'
import { FormQuestion, useTest } from '@Features/Test/Providers/TestProvider'
import { useAppSelector } from '@Hooks/ReduxHooks'
import { resetRunningTest } from '@Redux/RunningTestSlice/RunningTestSlice'
import { SnackbarConfig } from '@Utilities/SnackbarConfig'
import { current } from 'immer'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { generatePath, useNavigate, useSearchParams } from 'react-router-dom'
import { PulseLoader } from 'react-spinners'
import styled from 'styled-components'

const MultipleViewMode = () => {
	const { formQuestions, setFormQuestions, attempt, drawerOpened, openDrawer } = useTest()
	const { t } = useTranslation(['test'])
	const [searchParams, setSearchParams] = useSearchParams()

	const [submitQuestion] = useLazySubmitQuestionQuery()

	const { testData } = useAppSelector(state => state.RunningTest)
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const handleOnChange = async (answer: any, index: number, question: FormQuestion) => {
		 
		setFormQuestions(draft => {
			console.log(current(draft[index]))
			draft[index].answer = answer
			draft[index].submitted = false
		})
		if (answer !== null) {
			try {
				await submitQuestion({
					attempt_id: attempt.id,
					id: question.id,
					options: answer,
					question_id: question.id,
					type: question.type,
				})
			} catch (error) {
				if (testData) {
					const path = generatePath(`${routes.classroom}/${routes.course}/${routes.mcqUnit}`, {
						label: testData.classroomLabel,
						cId: testData.course_id.toString(),
						id: testData.unit_id.toString(),
					})
					dispatch(resetRunningTest())
					navigate(path)
				}
			}
		}
	}

	const handleFocus = (index: number, question: FormQuestion) => {
		setFormQuestions(draft => {
			draft[index].touched = true
		})
		searchParams.set('q', formQuestions[index].id.toString())
		setSearchParams(searchParams)
	}

	const submitTest = useSubmitTest()
	const [submitLoading, setSubmitLoading] = useState(false)

	const handleSubmitTest = async () => {
		setSubmitLoading(true)
		try {
			await submitTest()
			setSubmitLoading(false)
		} catch (error) {}
	}
	const { isOnline } = useAppSelector(state => state.settings)

	const { openSnackbar } = useSnackbar()
	const [uploadProgress, setUploadProgress] = useState<UploadProgressArgs | undefined>()
	const onAttachmentsChange = async (files: FileList | null, question: FormQuestion, qIndex: number) => {
		if (!files || files.length === 0) return
		 
		const formData = new FormData()
		for (let index = 0; index < files.length; index++) {
			const file = files.item(index)
			if (!file) return
			if (file.size / 1024 / 1024 > 5) {
				openSnackbar({ ...SnackbarConfig.disclaimer, text: `${file.name} exceeds 5MB` })
				return;
			}
			formData.set('file', file as Blob)
			formData.set('folder', '')
			try {
				const {
					data: {
						data: { uri },
					},
				} = await uploadFileWithProgress({
					formData,
					progressCallback(progress) {
						setUploadProgress({
							file,
							progress,
						})
					},
				})
				setUploadProgress(undefined)
				let answer = {}
				const selectedQuestion = formQuestions[qIndex] as IEssayQuestion
				if (!selectedQuestion) return
				if (!selectedQuestion.answer || !selectedQuestion.answer.answer) {
					answer = {
						type: 'attachment',
						answer: JSON.stringify([uri]),
					}
				} else {
					if (!selectedQuestion.answer.answer) return
					const answerFiles = JSON.parse(selectedQuestion.answer.answer) as string[]
					answerFiles.push(uri)
					answer = {
						type: 'attachment',
						answer: JSON.stringify(answerFiles),
					}
				}
				handleOnChange(answer, qIndex, question)
			} catch (error) {
				setUploadProgress(undefined)
				openSnackbar({ ...SnackbarConfig.disclaimer, text: `File upload failed` })

			}
		}
	}
	return (
		<TestWrapper online={isOnline}>
			{formQuestions.map((question, index) => {
				return (
					<div
						onMouseDown={() => {
							if (question.type === 'ordering') handleFocus(index, question)
						}}
						id={`question-${question.id}`}
						tabIndex={question.id}
						key={`${question.id}-${question.weight}`}
					>
						<TestQuestion
							essayQuestionProps={{
								onAttachmentsChange(files) {
									onAttachmentsChange(files, question, index)
								},
								uploadProgress,
								accept: 'application/pdf, .png, .jpg, .jpeg',
								textChangeDelay: 1000,
							}}
							index={index}
							question={question}
							onChange={answer => handleOnChange(answer, index, question)}
							key={question.id}
						/>
						<Spacer mb={'2rem'} />
					</div>
				)
			})}
			<FlexLayout mt="2rem" alignItems={'center'} justifyContent="flex-end">
				<RaisedButton disabled={!isOnline} btnSize={'medium'} outlined bgFallback onClick={handleSubmitTest}>
					{submitLoading ? <PulseLoader size={5} /> : <>{t('submit')}</>}
				</RaisedButton>
			</FlexLayout>
		</TestWrapper>
	)
}

export default MultipleViewMode

const TestWrapper = styled.div<{ online: boolean }>`
	pointer-events: ${props => !props.online && 'none'};
	opacity: ${props => !props.online && '0.5'};
`
